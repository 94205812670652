<template>
  <section>
    <el-dialog v-model="dialogShow"  
      :visible.sync="dialogShow"
               @closed="checkclose"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               style="width: 110%; left:-5%; top:-80px;height:800px;"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;position:relative;top:-3px;">{{myheadText}}</span>
      </template>
      

      <!--工具条-->
      <!-- <toolbar
        :buttonList="buttonList"
        @callFunction="callFunction"
        :buttonListmsg="RegularMeetingmsg"
      ></toolbar> -->
      <el-button type="primary" @click="getRegularMeetings" size="small" icon="el-icon-refresh" round >刷新</el-button>
      <el-button type="primary" @click="handleAdd" size="small" icon="el-icon-plus" round >新增</el-button>
      <el-button type="primary" @click="handleDelete" size="small" icon="el-icon-delete" round >删除</el-button>
      <el-button type="primary" @click="handleEdit" size="small" icon="el-icon-edit" round >修改审批人</el-button>
      <el-button type="primary" @click="handleEditCCUser" size="small" icon="el-icon-edit" round >修改抄送人</el-button>
      <el-button type="primary" @click="handleEditFabuUser" size="small" icon="el-icon-edit" round >修改发布人</el-button>
      <el-button type="primary" @click="handleEditRangeUser" size="small" icon="el-icon-edit" round >修改可见成员</el-button>
      <!--列表内容-->
      <el-table
        :data="meetings"
        highlight-current-row
        @current-change="selectCurrentRow"
        :row-style="{height:'40px'}"
        :cell-style="{padding:'0px'}"
        :header-cell-style="{background:'#dce4f1',color:'#000000'}"
        :row-class-name="tableRowClassName"
        max-height="450"
        style="width: 100%;margin-top:5px"
      >
        <el-table-column type="index"></el-table-column>
        <el-table-column label="会议名称" prop="MeetingName" min-width="100px" align="center"></el-table-column>
        <el-table-column label="例会周期" prop="MeetingPeriod" align="center"></el-table-column>
        <el-table-column label="审批人" prop="ApprovalName" align="center"></el-table-column>
        <el-table-column label="发布人" prop="UsePeopleName" align="center"></el-table-column>
        <el-table-column label="抄送人" prop="CCUserName" align="center"></el-table-column>
        <el-table-column label="可见成员" prop="RangeUserName" align="center"></el-table-column>
        <el-table-column label="备注" prop="Remark" align="center"></el-table-column>
        <el-table-column
          label="维护时间"
          prop="LastUpdateTime"
          :formatter="formatCreatedOn"
          align="center"
        ></el-table-column>
      </el-table>
      <br/>
      <!--新增界面-->
      <el-dialog
        :visible.sync="addFormVisible"
        v-model="addFormVisible"
        @close="diaclose"
        :modal="false"
        center
      >
      <template slot="title">
          <span style="color:#FFFFFF;font-size: 16px;">新增例会维护</span>
      </template>
        <el-form
          :model="addForm"
          label-width="85px"
          :rules="addFormRules"
          ref="addForm"
          style="white-space:nowrap;"
        >
          <el-form-item label="会议名称:" prop="MeetingName">
            <el-input
              v-model="addForm.MeetingName"
              maxlength="30"
              show-word-limit
              auto-complete="off"
              placeholder="请填写会议名称"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="22">
              <el-form-item label="会议发布人:" prop="UsePeopleName">
                <el-input v-model="addForm.UsePeopleName" placeholder="请选择发布人" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <el-button type="text">
                <userchoosetool @usercallFunction="usercallFunction" :callbackmsg="doublepeople"></userchoosetool>
              </el-button>
            </el-col>
          </el-row>
          <el-row>
            <!-- <el-col :span="12">
                              <el-form-item label="例会周期:" prop="MeetingPeriod">
                                  <el-input v-model="addForm.MeetingPeriod" placeholder="请填写例会周期"></el-input>
                              </el-form-item>
            </el-col>-->
            <el-col :span="12">
              <el-form-item label="例会周期:">
                <el-col :span="6">
                  <el-radio v-model="Meetingstatus" label="每天">每天</el-radio>
                </el-col>
                <el-col :span="6">
                  <el-radio v-model="Meetingstatus" label="每周">每周</el-radio>
                </el-col>
                <el-col :span="6">
                  <el-radio v-model="Meetingstatus" label="每月">每月</el-radio>
                </el-col>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-row>
                <el-col :span="20">
                  <el-form-item label="审批人:" prop="ApprovalName">
                    <el-input v-model="addForm.ApprovalName" placeholder="请选择审批人" readonly></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-button type="text">
                    <userchoosetool @assigncallFunction="assigncallFunction" :callbackmsg="assignpeo"></userchoosetool>
                  </el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <el-row>

            <el-col :span="12">
              <el-row>
                <el-col :span="20">
                  <el-form-item label="抄送人:" prop="CCUserName">
                    <el-input v-model="addForm.CCUserName" placeholder="请选择抄送人" readonly>{{ccusername}}</el-input>
                    <el-button type="text" size="mini" @click="handleChooseCCUser">选择人员</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-form-item label="备注:" prop="Remark">
            <el-input v-model="addForm.Remark" type="textarea" maxlength="50" show-word-limit></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="goon" @click.native="addSubmit">提交</el-button>
        </div>
      </el-dialog>


    </el-dialog>


    <!--修改审批人界面-->
    <el-dialog
      :visible.sync="editFormVisible"
      v-model="editFormVisible"
      :close-on-click-modal="false"
      style="width: 60%; left: 20%"
      center
    >
    <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">修改审批人</span>
    </template>
      <el-form :model="editForm" label-width="85px">
        <el-row>
          <el-col :span="0.1" style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="20">
            <el-form-item label="审批人:" prop="ApprovalName">
              <el-input v-model="editForm.ApprovalName" placeholder="请选择审批人" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="text">
              <userchoosetool @assigncallFunction="assigncallFunction" :callbackmsg="assignpeo"></userchoosetool>
            </el-button>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click="editSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!--修改抄送人界面-->
    <el-dialog
      :visible.sync="editCCFormVisible"
      v-model="editCCFormVisible"
      :close-on-click-modal="false"
      style="width: 60%; left: 20%"
      center
    >
    <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">修改抄送人</span>
    </template>
      <el-form :model="editCCForm" label-width="85px">
        <el-row>
          <el-col :span="0.1" style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="20">
            <el-form-item label="抄送人:" prop="CCUserName">
              <el-input v-model="editCCForm.CCUserName" placeholder="请选择抄送人" readonly>{{ccusername2}}</el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
              <el-button type="text" size="mini" @click="handleChooseCCUser2">选择人员</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click="editCCSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!--修改可见人界面-->
    <el-dialog
      :visible.sync="editRangeFormVisible"
      v-model="editRangeFormVisible"
      :close-on-click-modal="false"
      style="width: 60%; left: 20%"
      center
    >
    <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">修改可见成员</span>
    </template>
      <el-form :model="editRangeForm" label-width="85px">
        <el-row>
          <el-col :span="0.1" style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="20">
            <el-form-item label="可见成员:" prop="RangeUserName">
              <el-input v-model="editRangeForm.RangeUserName" placeholder="请选择可见成员" readonly>{{rangeusername}}</el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
              <el-button type="text" size="mini" @click="handleChooseRangeUser">选择人员</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click="editRangeSubmit">提交</el-button>
      </div>
    </el-dialog>

    <!--修改发布人界面-->
    <el-dialog
      :visible.sync="editFabuFormVisible"
      v-model="editFabuFormVisible"
      :close-on-click-modal="false"
      style="width: 60%; left: 20%"
      center
    >
    <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">修改发布人</span>
    </template>
      <el-form :model="editFabuForm" label-width="85px">
        <el-row>
          <el-col :span="0.1" style="margin-top:10px">
            <span style="color: red;">*</span>
          </el-col>
          <el-col :span="20">
            <el-form-item label="发布人:" prop="UsePeopleName">
              <el-input v-model="editFabuForm.UsePeopleName" placeholder="选择发布人" readonly>{{fabuusername}}</el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
              <el-button type="text" size="mini" @click="handleChooseCCUser3">选择人员</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click="editFabuSubmit">提交</el-button>
      </div>
    </el-dialog>



        <el-dialog
        title="选择人员"
        :visible.sync="addUserVisible"
        v-model="addUserVisible"
        width="75%"
        center
        append-to-body
        >
        <UserChoose
            :data="choosedusers"
            :all="true"
            :single="false"
            @callback="chooseUserCallBack"
        ></UserChoose>
        </el-dialog>


        <el-dialog
        title="选择人员"
        :visible.sync="addUserVisible2"
        v-model="addUserVisible2"
        width="75%"
        center
        append-to-body
        >
        <UserChoose
            :data="choosedusers"
            :all="true"
            :single="false"
            @callback="chooseUserCallBack2"
        ></UserChoose>
        </el-dialog>


        <el-dialog
        title="选择人员"
        :visible.sync="addRangeUserVisible"
        v-model="addRangeUserVisible"
        width="75%"
        center
        append-to-body
        >
        <UserChoose
            :data="choosedRangeUsers"
            :all="true"
            :single="false"
            @callback="chooseRangeUserCallBack"
        ></UserChoose>
        </el-dialog>


        <el-dialog
        title="选择人员"
        :visible.sync="addUserVisible3"
        v-model="addUserVisible3"
        width="75%"
        center
        append-to-body
        >
        <UserChoose
            :data="choosedfabuusers"
            :all="true"
            :single="false"
            @callback="chooseUserCallBack3"
        ></UserChoose>
        </el-dialog>


  </section>
</template>
<script>
import { Loading } from 'element-ui'
import util from "../../util/date";
import {
  QueryMeetingUphold,
  GetUserPostList,
  AddRegularMeeting,
  QueryMeetingUpholdByCreate,
  DeleteMeetingUpholdById,
  UpdateMeetingUphold,
  UpdateMeetingUpholdCCUser,
  UpdateMeetingUpholdRangeUser,
  UpdateMeetingUpholdFabuUser
} from "../api/oa";
import Userchoosetool from "../components/Userchoosetool";
import UserChoose from "../components/UserChoose";

import UploadFiles from "../components/UploadFiles";
export default {
  name: 'lihuiweihu',
  components: { UserChoose, Userchoosetool, UploadFiles },
  data () {
    return {
      popo1:false,
      filters: {
        name: ""
      },
      CommonTaskClassifyText:'',
      loading: '',
      currentYearplanActiveId: null,
      currentActiveId: null,
      // editor: null,
      myheadText:'例会维护配置',
      mytype:'normal',
      checkForm: {
        Id: null,
        WorkItemType: null,
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null,
        Files: []
      },
      addProgressForm: {            //进度新增表单初始化
        ProgressDescription: null,
        CreatedBy: null,
        CreatedId: null
      },
      addProgressFormRule: {
        ProgressDescription: [
          { required: true, message: '请输入进度描述', trigger: 'blur' }
        ]
      },
      WorkItemType: null,
      activeName: 'tabTask',
      pValue1: 0,   //进度条数值
      pValue2: 0,
      taskprogress: [],
      istaskpro: true,
      dialogShow:false,
      addProgressVisible: false,
      progresscheck:false,
      operation1: true,
      fileList: [],
      key: 0,
      yearplans:[],
      mapReview:new Map(),
      meetings: [],
      addFormVisible: false,
      addForm: {
        MeetingName: null,
        MeetingPeriod: null,
        ApprovalName: null,
        UsePeopleName: null,
        UsePeopleCode: null,
        CreatedId: null,
        CreatedBy: null,
        Remark: null
      },
      addFormRules: {
        MeetingName: [
          { required: true, message: "请填写会议名称", trigger: "blur" }
        ],
        MeetingPeriod: [
          { required: true, message: "请填写例会周期", trigger: "blur" }
        ],
        ApprovalName: [
          { required: true, message: "请选择审批人", trigger: "blur" }
        ],
        UsePeopleName:[
          { required: true, message: "请选择会议发布人", trigger: "blur" }
        ]
      },
      doublepeople: [],
      Meetingstatus: "每天",
      assignpeo: "assignpeo",
      ccusername:'',
      ccusername2:'',
      rangeusername:'',
      fabuusername:'',
      editFormVisible: false,
      editForm: {
        Id: null,
        ApprovalName: null,
        ApprovalCode: null
      },
      editCCForm: {
        Id: null,
        CCUserName: null,
        CCUserCode: null
      },
      editRangeForm: {
        Id: null,
        RangeUserName: null,
        RangeUserCode: null
      },
      editFabuForm: {
        Id: null,
        UsePeopleName: null,
        UsePeopleCode: null
      },
      editCCFormVisible: false,
      editRangeFormVisible: false,
      editFabuFormVisible: false,
      addUserVisible:false,
      addUserVisible2:false,
      addRangeUserVisible:false,
      addUserVisible3:false,
      choosedusers: [],//抄送人
      choosedRangeUsers: [],//可见成员
      choosedfabuusers: [],//发布人
    }
  },
  props: ['headerText','data','type'],// 接收父组件的方法
  watch: {
    content () {
      // this.editor.txt.html(this.content)
    }
  },
  methods:
  {
    //新增提交
    addSubmit() {
      let para = Object.assign({}, this.addForm);
      var user = JSON.parse(window.localStorage.user);
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      para.MeetingPeriod = this.Meetingstatus;
      if (
        this.addForm.MeetingName == null ||
        this.addForm.ApprovalName == null
      ) {
        this.tipMessage();
        return;
      }
      var exists=false;
      for(var a=0;a<this.meetings.length;a++)
      {
        if(this.meetings[a].MeetingName==this.addForm.MeetingName)
        {
          exists=true;
          break;
        }
      }
      if(exists)
      {
        this.$message({
          message: "会议名称不能重复，请检查！",
          type: "warning"
        });
        return;
      }
      
      this.Loadingstart();
      AddRegularMeeting(para).then(res => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: "success"
          });
          this.Loadignend();
          this.$refs["addForm"].resetFields();
          this.addFormVisible = false;
          this.getRegularMeetings();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error"
          });
        }
      });
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack(data) {
        console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.ccusername=str;
        this.addForm.CCUserCode = idstr;
        this.addForm.CCUserName = str;
        this.addUserVisible=false;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack2(data) {
        console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.ccusername2=str;
        this.editCCForm.CCUserCode = idstr;
        this.editCCForm.CCUserName = str;
        this.addUserVisible2=false;
    },
    //监听子组件事件 选择人员回调
    chooseRangeUserCallBack(data) {
        console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        console.log(this.rangeusername)
        this.rangeusername=str;
        this.editRangeForm.RangeUserCode = idstr;
        this.editRangeForm.RangeUserName = str;
        this.addRangeUserVisible=false;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack3(data) {
        console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.fabuusername=str;
        this.editFabuForm.UsePeopleCode = idstr;
        this.editFabuForm.UsePeopleName = str;
        this.addUserVisible3=false;
    },
    //编辑抄送人提交
    editCCSubmit() {
      let para = Object.assign({}, this.editCCForm);
      var user = JSON.parse(window.localStorage.user);
      para.LastUpdateUserCode = user.sub;
      para.LastUpdateUserName = user.name;
      this.$confirm("确认修改抄送人吗？", "提示", {}).then(() => {
        this.Loadingstart();
        UpdateMeetingUpholdCCUser(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.currentRow = null;
            this.editCCFormVisible = false;
            this.getRegularMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      });
    },
    //编辑可见成员提交
    editRangeSubmit() {
      console.log('editRangeSubmit');
      let para = Object.assign({}, this.editRangeForm);
      var user = JSON.parse(window.localStorage.user);
      para.LastUpdateUserCode = user.sub;
      para.LastUpdateUserName = user.name;
      // console.log(para);
      // return;
      this.$confirm("确认要提交修改可见成员吗？", "提示", {}).then(() => {
        this.Loadingstart();
        UpdateMeetingUpholdRangeUser(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.currentRow = null;
            this.editRangeFormVisible = false;
            this.getRegularMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      });
    },
    //编辑发布人提交
    editFabuSubmit() {
      let para = Object.assign({}, this.editFabuForm);
      var user = JSON.parse(window.localStorage.user);
      para.LastUpdateUserCode = user.sub;
      para.LastUpdateUserName = user.name;
      this.$confirm("确认要修改发布人吗？", "提示", {}).then(() => {
        this.Loadingstart();
        UpdateMeetingUpholdFabuUser(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.currentRow = null;
            this.editFabuFormVisible = false;
            this.getRegularMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      });
    },
    handleChooseCCUser2()
    {
        this.addUserVisible2=true;
    },
    handleChooseRangeUser()
    {
        this.addRangeUserVisible=true;
    },
    handleChooseCCUser3()
    {
        this.addUserVisible3=true;
    },
    //编辑审批人提交
    editSubmit() {
      if(!this.editForm.ApprovalCode)
      {
        this.$message({
          message: '审批人不能为空',
          type: "warning"
        });
        return;
      }
      let para = Object.assign({}, this.editForm);
      var user = JSON.parse(window.localStorage.user);
      para.LastUpdateUserCode = user.sub;
      para.LastUpdateUserName = user.name;
      this.$confirm("确认修改审批人吗？", "提示", {}).then(() => {
        this.Loadingstart();
        UpdateMeetingUphold(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.currentRow = null;
            this.editFormVisible = false;
            this.getRegularMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      });
    },
    handleChooseCCUser()
    {
        this.addUserVisible=true;
    },
    //审核人回调
    assigncallFunction(newdata) {
      this.addForm.ApprovalCode = newdata[0];
      this.editForm.ApprovalCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then(res => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.ApprovalName = res.data.data[0].name;
          this.editForm.ApprovalName = res.data.data[0].name;
        }
      });
    },
    //会议发布人回调
    usercallFunction(newdata){
      let fabuusers = "";
      let fabuusercodes = "";
      if(newdata.length == 1){
        this.addForm.UsePeopleCode = newdata[0];
        let para = {userId: newdata[0]};
        GetUserPostList(para).then((res) => {
                this.addForm.UsePeopleName = res.data.data[0].name;
        });
      }else{
        newdata.map(item => {
          fabuusercodes += item + ",";
          this.addForm.UsePeopleCode = fabuusercodes.replace("null", "");
          let para = {userId: item};
          GetUserPostList(para).then((res) => {
                  fabuusers += res.data.data[0].name + ",";
                  this.addForm.UsePeopleName =  fabuusers.replace("null", "");
                  this.addForm.UsePeopleName = this.addForm.UsePeopleName.substr(0, this.addForm.UsePeopleName.length - 1);
          });
        })
      }
    },
    diaclose() {
      this.$refs.addForm.resetFields();
    },
    formatCreatedOn: function(row, colume) {
      return !row.LastUpdateTime || row.LastUpdateTime == ""
        ? ""
        : util.formatDate.format(new Date(row.LastUpdateTime), "yyyy-MM-dd");
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    tableRowClassName({row, rowIndex}){
      if (rowIndex % 2 !== 0) {
      return 'warning-row';
      }
      return '';    
    },
    //修改抄送人
    handleEditCCUser() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "warning"
        });
        return;
      }
      this.editCCFormVisible = true;
      this.editCCForm.Id = row.Id;
    },
    //修改发布人
    handleEditFabuUser() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "warning"
        });
        return;
      }
      this.editFabuFormVisible = true;
      this.editFabuForm.Id = row.Id;
    },
    //修改可见人
    handleEditRangeUser() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "warning"
        });
        return;
      }
      this.editRangeFormVisible = true;
      this.editRangeForm.Id = row.Id;
    },
    //修改审批人
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "warning"
        });
        return;
      }
      this.editFormVisible = true;
      this.editForm.Id = row.Id;
    },
    //删除
    handleDelete() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error"
        });
        return;
      }
      this.$confirm("确认删除该条例会信息吗？", "提示", {}).then(() => {
        this.Loadingstart();
        let para = { meetingUpholdId: row.Id };
        DeleteMeetingUpholdById(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.currentRow = null;
            this.getRegularMeetings();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      }).catch(()=>{});
    },
    getRegularMeetings() {
      var user = JSON.parse(window.localStorage.user);
      QueryMeetingUpholdByCreate({createUserCode: user.sub,meetingname: this.filters.name}).then(res => {
        this.meetings = res.data.response;
      });
    },
    //新增
    handleAdd() {
      this.addFormVisible = true;
    },
    checkclose () {
      this.pValue1 = 0;
      this.$emit("closeFunction", {});
    },
    Loadingstart () {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend () {
      this.loading.close();
    },
    handleFormatTime(t)
    {
      return t;
    },
  },
  mounted () {
    
    this.dialogShow = true;
    this.getRegularMeetings();
    return;
    if(this.headerText)
    {
      this.myheadText = this.headerText;
    }
    if(this.type)
    {
      this.mytype = this.type;
      console.log(this.mytype)
    }
  }
}
</script>